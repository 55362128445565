<template>
  <div class="app-container">
    <div class="top_box">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline account-top"
      >
        <el-form-item label="ID">
          <el-input
            v-model="queryForm.id"
            placeholder="群组id..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="搜索">
          <el-input
            v-model="queryForm.search"
            placeholder="名称、简介等..."
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          v-loading="listLoading"
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          class="tableList2"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          style="width: 100%"
          algin="center"
          :default-sort="{ prop: 'creation', order: 'descending' }"
          @sort-change="handleSort"
        >
          <el-table-column prop="avatar" label="头像" width="60">
            <template v-slot="scope">
              <img
                width="29px"
                height="29px"
                v-if="scope.row.avatar !== null"
                :src="scope.row.avatar"
                alt=""
              />
              <img
                width="29px"
                height="29px"
                v-else
                src="../../assets/imgs/mrtx.png"
                alt=""
              />
            </template>
          </el-table-column>

          <el-table-column
            v-for="(item, index) in headerList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
          ></el-table-column>
          <el-table-column prop="" label="标签">
            <template v-slot="scope">
              <div @click="tagComponentsFun(scope.row)">
                <el-tag
                  class="item-tag"
                  size="medium"
                  style="margin-right: 10px;margin-bottom: 10px;"
                  type="success"
                  v-for="(item, i) in scope.row.tags"
                  :key="i"
                  :closable="true"
                  @close="closeTagFun(scope.row, item)"
                >
                  {{ item.tag }}
                </el-tag>
                <tagComponents size="mini" @updateTagFun="updateTagFun" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="creation"
            sortable="custom"
          ></el-table-column>

          <el-table-column label="操作" width="120">
            <template v-slot="scoped">
              <el-button
                @click="showDia(scoped.row.id, scoped.row)"
                type="text"
              >
                标记
              </el-button>
              <el-button
                @click="$router.push(`/group/detail/${scoped.row.id}`)"
                type="text"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
    <!-- 标签弹框 -->
    <el-dialog
      title="添加标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <LabelForm
        @reciverLabel="reciverLabel"
        @updateFun="updateFun"
        ref="LabelFormRef"
        tagType="group"
        :singleInfo="singleInfo"
        v-if="dialogVisible"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getGroupList } from '@/api/whatsApp'
import { addTagForGroup } from '@/api/tagged'
import { delGroupApi, addGroupApi } from '@/api/tag'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { getManagerStatus } from '@/utils/manager'
import LabelForm from '@/components/LabelForm.vue'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'searchGroup',
  mixins: [cacheMixin],
  components: {
    LabelForm
  },
  data() {
    return {
      list: null,
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      multipleSelection: [],
      queryForm: {
        page_index: 1,
        page_size: 10,
        search: '',
        id: '',
        ordering: '-creation'
      },
      headerList: [
        {
          label: '群组ID',
          prop: 'id'
        },
        {
          label: '群组名称',
          prop: 'name'
        },

        {
          label: '群组简介',
          prop: 'desc'
        }
        // {
        //   label: '创建时间',
        //   prop: 'creation'
        // }
      ],
      dialogVisible: false,
      userId: null,
      singleInfo: null
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    async fetchData() {
      this.listLoading = true
      const res = await getGroupList(this.queryForm)
      // console.log('res:', res)
      this.count = res.count
      this.list = res.items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    showDia(id, row) {
      this.singleInfo = { ...row }
      this.dialogVisible = true
      this.userId = id
      this.$nextTick(() => {
        if (row.tags && row.tags.length > 0) {
          this.$refs.LabelFormRef.ruleForm.tags = row.tags.map(item => {
            return item.id
          })
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    async sendResult(val) {
      const data = {
        ids: val.tags
      }
      await addTagForGroup(this.userId, data)
      this.dialogVisible = val.flag
    },
    // 收到选中的标签
    reciverLabel(val) {
      // 收到值发送请求
      if (val.tags.length !== 0) {
        this.sendResult(val)
      } else {
        // 否则关闭弹框
        this.dialogVisible = val.flag
      }
      this.fetchData()
    },
    handleSort({ column, prop, order }) {
      if (order === null) {
        this.queryForm.ordering = null
      } else if (order === 'descending') {
        this.queryForm.ordering = '-' + prop
      } else {
        this.queryForm.ordering = prop
      }

      this.fetchData()
    },
    //
    tagComponentsFun(row) {
      this.singleInfo = { ...row }
    },
    // 删除tag标签
    closeTagFun(group, row) {
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          delGroupApi(group.id, row.id).then(res => {
            this.$message({
              type: 'success',
              message: '标签已删除！'
            })
            this.fetchData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //
    updateTagFun(res) {
      addGroupApi({
        tag: res.id,
        group: this.singleInfo.id
      })
        .then(res => {
          this.$message({
            type: 'success',
            message: '群组已标记！'
          })
          this.fetchData()
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: '群组标记失败！'
          })
        })
    },
    // 刷新
    updateFun() {
      this.dialogVisible = false
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  ::v-deep span.el-input__suffix {
    right: 20px;
  }
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 35px);
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
