import request from '@/plugins/axios'
// 匹配模式(旧接口)
export function messages1_Api(params) {
  return request({
    url: '/whatsapp/messages',
    method: 'get',
    params
  })
}
// 分词模式
export function messages2_Api(params) {
  return request({
    url: '/search_indexes/messages:match',
    method: 'get',
    params
  })
}
// 短句模式
export function messages3_Api(params) {
  return request({
    url: '/search_indexes/messages:match_phrase',
    method: 'get',
    params
  })
}
// 新接口(分词模式)
export function messagesApi(params) {
  return request({
    url: '/search_indexes/messages',
    method: 'get',
    params
  })
}
export function getGroupList(params) {
  return request({
    url: '/whatsapp/groups',
    method: 'get',
    params
  })
}
export function getUserList(params) {
  return request({
    url: '/whatsapp/users',
    method: 'get',
    params
  })
}

export function getInviteLinkList(params) {
  return request({
    url: '/whatsapp/invite_links',
    method: 'get',
    params
  })
}

export function addMessagesLabel(id, data) {
  return request({
    url: `/tagged/messages/${id}:batch_tag`,
    method: 'post',
    data
  })
}

// 获得单个用户信息
export function getSingleUserInfo(id) {
  return request({
    url: `/whatsapp/users/${id}`,
    method: 'get'
  })
}
// 获得单个组信息
export function getSingleGroupInfo(id) {
  return request({
    url: `/whatsapp/groups/${id}`,
    method: 'get'
  })
}

// 获得群成员信息
export function getMembers(params) {
  return request({
    url: '/whatsapp/members',
    method: 'get',
    params
  })
}
