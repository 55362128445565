<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="标签名称" prop="tags">
        <div class="first_box">
          <el-select style="width: 100%;" v-model="ruleForm.tags" multiple>
            <el-option
              v-for="item in tagsList"
              :key="item.id"
              :label="item.tag"
              :value="item.id"
            ></el-option>
          </el-select>
          <!-- <div @click="$router.push('/tagged/tag')" class="other_label">
            添加其他标签
          </div> -->
        </div>
      </el-form-item>
      <!-- <el-form-item label="新标签">
        <tagComponents @updateTagFun="updateTagFun" />
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">
          确定
        </el-button>
        <el-button @click="closeDia">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addUserApi, addMsgApi, addGroupApi } from '@/api/tag'
import { getTagAll } from '../api/tagged'
export default {
  name: 'LabelForm',
  props: {
    singleInfo: {
      type: Object,
      default: {}
    },
    tagType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ruleForm: {
        tags: []
      },
      rules: {
        tags: [{ required: true, message: '请选择标签', trigger: 'change' }]
      },
      tagsList: []
    }
  },
  created() {
    this.getTagsList()
  },
  methods: {
    async getTagsList() {
      const res = await getTagAll()
      this.tagsList = res
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit('reciverLabel', {
            tags: this.ruleForm.tags,
            flag: false
          })
          this.$message.success('添加成功！')
        } else {
          return false
        }
      })
    },
    closeDia() {
      this.$emit('reciverLabel', {
        tags: [],
        flag: false
      })
    },
    updateTagFun(res) {
      this.getTagsList()
      //
      if (this.tagType === 'msg') {
        addMsgApi({
          tag: res.id,
          message: this.singleInfo.id
        })
          .then(res => {
            this.$message({
              type: 'success',
              message: '消息已标记！'
            })
            this.$emit('updateFun')
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '消息标记失败！'
            })
          })
      } else if (this.tagType === 'user') {
        addUserApi({
          tag: res.id,
          user: this.singleInfo.id
        })
          .then(res => {
            this.$message({
              type: 'success',
              message: '用户已标记！'
            })
            this.$emit('updateFun')
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '用户标记失败！'
            })
          })
      } else if (this.tagType === 'group') {
        addGroupApi({
          tag: res.id,
          group: this.singleInfo.id
        })
          .then(res => {
            this.$message({
              type: 'success',
              message: '群组已标记！'
            })
            this.$emit('updateFun')
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '群组标记失败！'
            })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.first_box {
  display: flex;
  cursor: pointer;
  .other_label {
    font-size: 14px;
    color: #409eff;
    text-decoration: underline;
  }
}
</style>
